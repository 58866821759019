import React from 'react';

const resources = [
  {
    city: 'Airdrie',
    url: 'http://www.airdrie.ca/index.cfm?serviceID=874',
  },
  {
    city: 'Black Diamond',
    url: 'http://www.town.blackdiamond.ab.ca/156/Permits-Applications',
  },
  {
    city: 'Beiseker',
    url: 'http://www.beiseker.com/permits/',
  },
  {
    city: 'Calgary',
    url: 'http://www.calgary.ca/PDA/pd/Pages/Permits/Development-permits/Development-permits.aspx',
  },
  {
    city: 'Canmore',
    url: 'http://www.canmore.ca/residents/residents-development-planning/development-permits-residents',
  },
  {
    city: 'Chestermere',
    url: 'http://www.chestermere.ca/index.aspx?NID=511',
  },
  {
    city: 'Cochrane',
    url: 'https://www.cochrane.ca/608/Development-Permits',
  },
  {
    city: 'Crossfield',
    url: 'http://www.crossfieldalberta.com/en/living-in-crossfield/applications-permits-and-planning-documents.asp',
  },
  {
    city: 'High River',
    url: 'http://www.highriver.ca/index.php/en/residents/applications-permits',
  },
  {
    city: 'Nanton',
    url: 'http://www.nanton.ca/159/Permits',
  },
  {
    city: 'Okotoks',
    url: 'http://www.okotoks.ca/town-services/planning-development/planning/development-permits',
  },
  {
    city: 'Olds',
    url: 'http://www.olds.ca/planning/forms.html',
  },
  {
    city: 'Red Deer',
    url: 'http://www.reddeer.ca/business/development-of-property/permits-and-applications/development-permits/',
  },
  {
    city: 'Rocky View County',
    url: 'http://www.rockyview.ca/BuildingPlanning/DevelopmentPermits.aspx',
  },
  {
    city: 'Strathmore',
    url: 'http://www.strathmore.ca/development_permits',
  },
  {
    city: 'Sylvan Lake',
    url: 'http://www.sylvanlake.ca/permits/development/q-a.html',
  },
  {
    city: 'Turner Valley',
    url: 'http://www.turnervalley.ca/permits/',
  },
];

const MunicipalResources = () => (
  <table className="table-price">
    <caption>Municipal Resources</caption>
    <tbody>
      {resources.map(({ city, url }) => (
        <tr key={city}>
          <td>
            <a href={url} target={city.replace(/[^a-z]/gi, '_')}>
              {city}
            </a>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default MunicipalResources;
