import React from 'react';
import { Layout } from '../../../components/Layout';
import MunicipalResources from '../../../components/MunicipalResources';

const DevelopmentPermits = () => (
  <Layout
    title="Development Permit Surveys Calgary & Red Deer | Axiom Geomatics"
    description="A DP survey is a plan showing your property in detail along with several adjacent lots, usually required to apply for a municipal Development Permit."
    keywords="development permit, development permit survey, dp, dp survey, new home construction, municipal survey, construction survey"
  >
    <main>
      <h1>Development Permit Surveys Calgary & Area</h1>
      <p>
        Most municipalities require a Development Permit for new developments, construction or
        renovations to an existing property. A <strong>Development Permit Survey</strong> is one of
        the components of such an application.
      </p>

      <p>
        A Development Permit Survey (DP Survey) provides a highly accurate, detailed document used
        by developers, landscape designers, architects and construction teams to determine the
        nature and location of structures that can be built or added to a property. Often times a
        <a href="https://axiomgeomatics.ca/services/construction-services/streetscapes">
          Streetscape
        </a>{' '}
        is also required as part of the documentation for the municipality.
      </p>

      <p>
        Axiom Geomatics provides DP Surveys that include your property as well as the size and shape
        of adjacent properties, per municipal requirements. Consider our many years of experience as
        a resource to obtain Our many years of experience can help you understand the overly complex
        nature of obtaining Development Permits from a municipality, we'll help you along the way.
      </p>

      <h2>Development Permit Survey Cost</h2>
      <p>
        Creation of a <strong>Development Permit Survey</strong> document entails a detailed survey
        of your property and those adjacent followed by drafting of the document. As with all survey
        work, the size of the lot, shape, terrain and complexity of the property affect the amount
        of time to complete the task.
      </p>

      <p>
        Municipalities may also require additional documentation and fees as part of the Development
        Permit application process. For your convenience we have included links to the various
        municipalities' websites for information specific to your location.
      </p>
    </main>
    <aside>
      <MunicipalResources />
    </aside>
  </Layout>
);

export default DevelopmentPermits;
